// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Next six lines are for setting up environment for Datatables
import $ from 'jquery';
global.$ = jQuery;
const jQuery = require('jquery')
window.jQuery = jQuery

//require("datatables.net");
//#require("datatables.net-bs4");

// Next line for providing a date picker ui component for rails/bootstrap4


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "@fortawesome/fontawesome-free/js/all";
import "bootstrap";
import "../stylesheets/application";
import "./custom";
import "./bootstrap-datepicker";
import "./datatables";
