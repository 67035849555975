$(function() {
  $('[data-toggle="tooltip"]').tooltip();
});

$(function() {
  $('[data-toggle="popover"]').popover();
});

$(function() {
  $('.custom-file-input').on('change', function() {
   let fileName = $(this).val().split('\\').pop();
   $(this).next('.custom-file-label').addClass("selected").html(fileName);
  });
});

$(function() {
  $('#datepicker1').datepicker({
    todayBtn: "linked",
    clearBtn: true,
    autoclose: true,
    format: "yyyy-mm-dd",
    orientation: "right top"
  });
});

$(function() {
  $('#datepicker2').datepicker({
    todayBtn: "linked",
    clearBtn: true,
    autoclose: true,
    format: "yyyy-mm-dd",
    orientation: "right top"
  });
});

$(function() {
  $('#datepicker3').datepicker({
    todayBtn: "linked",
    clearBtn: true,
    autoclose: true,
    format: "yyyy-mm-dd",
    orientation: "right top"
  });
});

$(function() {
  $('#datepicker4').datepicker({
    todayBtn: "linked",
    clearBtn: true,
    autoclose: true,
    format: "yyyy-mm-dd",
    orientation: "right top"
  });
});

$(function() {
  $('#datepicker5').datepicker({
    todayBtn: "linked",
    clearBtn: true,
    autoclose: true,
    format: "yyyy-mm-dd",
    orientation: "right top"
  });
});

$(document).ready(function() {
 var table = $('#dttb').DataTable({
    dom: '<"top"f>rt<"bottom"pi><"clear">',
    language: { search: '', searchPlaceholder: "Search..." },
    lengthChange: false,
    pageLength: 100
  });
});
